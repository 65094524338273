<template>
    <div class="myPlayer_container myPlayer_container_h5">
        <!-- :src="url" -->
      <!-- <video 
        class='myPlayer_video'
        controls="controls"
        ref='player'
        :src="url"
        @loadedmetadata='loaded'
      >
      
      </video> -->

      <video
        class="meeting_head_video_recorder_preview"
        :src="url"
        ref="player"
        controlslist="nodownload"
        preload="auto"
        playsinline
        muted
        autoplay
        controls
        webkit-playsinline
      ></video>
    </div>
</template>
<script>
export default {
  name:'myPlayer',
  data:()=>{
    return {
      url:'',
      muted:true,
    }
  },
  methods:{
    play(status=false){
      this.setMuted(status)
      this.$refs.player.play();
    },
    pause(){
      this.$refs.player.pause();
    },
    toggle(){
      if(this.$refs.player.paused){
        this.play();
      }else{
        this.pause();
      }
    },
    fullScreen(){
      var element = this.$refs.player;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      }else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      }
    },
    setMuted(status){
      this.$refs.player.muted = status;
      document.getElementsByTagName("video")[0].muted = true
    },
    switch(url,type,role){
      if(role == 1 && typeof url == "string"){
        return;
      }
      this.url = url;
      this.$refs.player.srcObject = url
      this.$refs.player.load();
      if(role == 1 ){
        setTimeout(()=>{
          this.$nextTick(()=>{
            this.$refs.player.muted = true
            document.getElementsByTagName("video")[0].muted = true
          })
        },1000)
      }
    },
    loaded(){
      this.$refs.player.play()
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.myPlayer_container,.myPlayer_video{
  width:100%;
  height:100%;
  height:53.33vw;
  position:relative;
  overflow:hidden
}
.myPlayer_container video{
  /* height:53.33vw !important; */
  width:53.33vw;
  height:auto;
  position: absolute;
  left:23.33vw;
  top:0;
}
.myPlayer_container video::-webkit-media-controls-timeline {
    display: none !important;
}
/* 全屏按钮 */
.myPlayer_container video::-webkit-media-controls-fullscreen-button {
    display:block !important;
    content: "\e719";
}
/* 音量按钮 */
.myPlayer_container video::-webkit-media-controls-mute-button {
    display: none!important;            
}

.meeting_head_video_recorder_preview{
  filter:brightness(101%) contrast(84%) saturate(113%);
  -webkit-filter:brightness(101%) contrast(84%) saturate(113%);
  transform: rotateY(180deg);
}
.myPlayer_container_h5 video::-webkit-media-controls{
 transform: rotateY(180deg) !important;
 -webkit-transform: rotateY(180deg) !important;
}
/* .myPlayer_container video::-webkit-media-controls-panel {
  background:transparent;
}
video::-webkit-media-controls-panel:first-child{
  background:red;
} */
</style>