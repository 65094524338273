<template>
  <div class="live" ref="livepage">
    <div class="live-content">
      <div class="live-meet-time" v-if="info.meeting_show_title">
        <span>会议编号：</span>
        <span class="green">{{ info.meeting_show_title || "" }}</span>
      </div>
      <div class="videocont">
        <div id="videoContainer" class="video_main_container">
          <!-- <myPlayer  :url='url' ref='playerItem' @loaded='loaded'></myPlayer> -->
          <myPlayer v-if="isShowPlayer()" :url='url' ref='playerItem' @loaded='loaded'></myPlayer>
          <VoicePlayer v-else ref='playerItem' :url="url" ></VoicePlayer>
        </div>
        <div
          class="live-stage"
          v-if="
          ((bindData.role == 0 && (!showVideo && !(!isPlay && curStep == 1))))  || 
          ((bindData.role == 1  && !isStartRecord))  && 
          (!url  || (!isPlay && curStep == 1) || curStep >= 2 || config.poster)"
          :style="{
            background: `url(&quot;${
              curStep == 2
                ? getImg(info.discussion_cover) || config.discussBg
                : getImg(info.default_cover) || config.common
            }&quot;)`,
          }"
        >
          <img :src="config.poster" alt="" v-if="config.poster" />
          <div class="tipscontent" v-if="curStep == 2"></div>
          <div class="tipscontent showstartime" v-if="curStep == 0">
            <div class="tipsdate">开始会议</div>
          </div>
          <div class="meettopplay" v-if="!isPlay && curStep == 1" @click="playVideo()">
            <div></div>
            <div><i></i><span></span></div>
          </div>
          <div class="tipscontent showreservetime" v-if="curStep == -1">
            <div class="tipsreservetime">{{ revserveTimeShow }}</div>
            <div class="tipsdate" v-if="info.start_time">
              开始时间：{{ info.start_time }}
            </div>
          </div>
        </div>
      </div>
      <div class="operacont">
        <div class="operastatus">
          <div class="operatext">
            <span>参会人次 : </span>
            <span class="green">{{ totalPeople }}</span>
          </div>
          <div class="operatext">
            <span>主持专家 : </span>
            <!-- 莫问，别人提的需求 -->
            <span :class="info.moderator_online || true ? 'green' : 'red'">{{
              info.moderator_online || true ? "在线" : "离线"
            }}</span>
          </div>
          <div class="operatext">
            <span>会议状态 : </span>
            <span :class="info.meet_time && !info.phase_time[3] ? 'green' : 'red'">{{
              getCountTime
            }}</span>
          </div>
        </div>
        <div class="operabtngroupmenbercontainer" v-show="getRole(1)">
          <div class="operabtngroupmember">
            <div
              v-for="(item, index) in config.step"
              :key="index"
              :class="`${item ? 'operabtnradius' : 'operalinearrow'} ${
                curStep > index / 2
                  ? 'beforestep'
                  : curStep == index / 2
                  ? 'curstep'
                  : getControlClass(index)
                  ? 'nextstep curactive'
                  : ''
              }`"
              @click="setStep(index)"
            >
              <div></div>
            </div>
          </div>
          <div class="operabtntextmember">
            <div
              class="btntext"
              v-for="(item, index) in config.step"
              :key="index"
              :class="`${curStep == index / 2 ? 'cursteptext' : ''} ${
                curStep > index / 2
                  ? 'beforetextstep'
                  : curStep == index / 2
                  ? 'curtextstep'
                  : getControlClass(index)
                  ? 'nexttextstep'
                  : ''
              }`"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="operabtngroupguestcontainer" v-show="getRole(0)">
          <div class="operabtngroupguest">
            <div
              v-for="(item, index) in config.step"
              :key="index"
              :class="`${item ? 'operabtnradius' : 'operalinearrow'} ${
                curStep > index / 2
                  ? 'beforestep'
                  : curStep == index / 2
                  ? 'curstep'
                  : curStep + 1 == index / 2
                  ? 'nextstep'
                  : ''
              }`"
            >
              <div>{{ getTipText(index) }}</div>
            </div>
          </div>

          <div class="operabtntextguest">
            <div
              class="btntext"
              v-for="(item, index) in config.step"
              :key="index"
              :class="`${curStep == index / 2 ? 'cursteptext' : ''} ${
                curStep > index / 2
                  ? 'beforetextstep'
                  : curStep == index / 2
                  ? 'curtextstep'
                  : curStep + 1 == index / 2
                  ? 'nexttextstep'
                  : ''
              }`"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="bannercont">
        <div
          v-for="(item, index) in banner"
          :key="index"
          @click="changeBanner(index)"
          :class="`${index == curComponentsIndex ? 'active' : ''} `"
        >
          <span class="meetborderdiv"
            ><span class="meetbannertext"
              >{{ item.text
              }}<span class="meetredtips" v-if="msgStatus[index] > 0"></span></span
          ></span>
        </div>
      </div>
      <div class="blockcont liveblockscroll" v-if="curComponentsIndex == 0">
        <component
          :is="banner[curComponentsIndex].component"
          :info="info"
          :person="bindData"
          @talk="sendMessage"
          @tipsError="delUserQuestion('delUserQuestion')"
          @playVoice="playVoice"
          :curIndex="curVoiceIndex"
          ref="component"
        ></component>
      </div>
      <div class="blockcont liveblockhidden" v-if="curComponentsIndex == 1">
        <component

          :is="banner[curComponentsIndex].component"
          :info="info"
          :person="bindData"
          @talk="sendMessage"
          @playVoice="playVoice"
          :curIndex="curVoiceIndex"
          ref="component"
        ></component>
      </div>
      <div
        class="voiceblock"
        :class="
          curStep == -1 && false ? 'nostart' : isRecord ? 'cordcolor' : 'nocordcolor'
        "
        v-if="getRole(1) && curStep != 1"
      >
        <div type="primary" class="thebutton" round>
          <div
            class="meettopshow"
          
          @touchstart="touchstart()"
            @touchcancel="touchcancel()"
            @touchend="touchend()"
          >
            <!-- @click="clickRecord" -->
          </div>
          <img class="voiceicon" :src="`${require('@/assets/images/voice.png')}`" /><span
            class="voicebtntext"
            >{{ btnvalue }}</span
          >
        </div>
      </div>
    </div>

    <div class="tipmeetings" @click="hidetips()" v-if="showTips">
      <div class="tipsmeetcontainer">
        <div class="tipsmeetdiv" @click.stop="">
          <div class="tipsmeettitle">{{ tipBlock.title }}</div>
          <div class="tipsmeetcontent">
            <div>
              <p
                class="tipmeetdesc"
                v-for="(item, index) in tipBlock.content"
                :key="index"
              >
                {{ item }}
              </p>
              <p class="noticetext">{{ tipBlock.notice }}</p>
            </div>
          </div>
          <div class="tipsmeetbtngroup">
            <div v-for="(item, index) in tipBlock.btn" :key="index">
              <el-button
                :type="tipBlock.events[index].type || eventsType[index]"
                @click="tipBlock.events[index].event(tipBlock.events[index].data)"
                >{{ item }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="live_reconnect" v-if="!isConnect">
      <div class="live_reconnect_main">
        <span>您的网络已中断，请切换至稳定的网络后，点击“重新连接”</span>
        <el-button type="primary" size="mini" @click="reloadPage">重新连接</el-button>
      </div>
    </div> -->
  </div>
</template>
<script>
import liveInfo from "./live-info";
import liveChat from "./live-chat";
import liveQuestion from "./live-question";
import wxInfo from "@/common/weixin";
import config from "@/common/config";
import tool from "@/common/tool";
import tips from "@/common/tips";
import axios from "@/common/axios";
import urls from "@/common/urls"
import upload from "@/common/uploads";
import recorder from '@/common/recorder'
import websocket from '@/common/websockets' 
import myPlayer from '@/components/player/player'
import VoicePlayer from '@/components/player/player1'
let poster = require("@/assets/images/poster.png");
let discussBg = require("@/assets/images/discussBg.png");
let common = require("@/assets/images/common.png");

export default {
  name: "live",
  data: () => {
    return {
      player: null,
      curComponentsIndex: 0,
      config: {
        video: {
          container: "#videoContainer", //“#”代表容器的ID，“.”或“”代表容器的class
          variable: "player", //该属性必需设置，值等于下面的new chplayer()的对象
          live: true,
          autoplay: true,
          poster: poster,
          mobileAutoFull: true, //在移动端播放后是否按系统设置的全屏播放
          h5container: "#videoplayer", //h5环境中使用自定义容器
          crossorigin: "Anonymous",
          crossdomain: "http://ioclass.imedunion.com/static/ckplayer/screenhost.xml",
        },
        poster: "",
        discussBg,
        common,
        step: ["开始", "", "课程", "", "讨论", "", "结束"],
      },
      banner: [
        {
          text: "提问区",
          component: liveQuestion,
          key: "chatList",
        },
        {
          text: "会议介绍",
          component: liveInfo,
          key: "info",
        },
        // {
        //     text:'管理频道',
        //     component:liveChat,
        //     key:'chatList'
        // },
      ],
      msgStatus: [0, 0, 0],
      curStep: -1,
      info: {
        chatList: [],
      },
      id: "",
      meetType: "",
      showVideo:false,
      currole: null,
      // 只是为了记录  websocket 事件类型多少个
      events: {
        chat: "dealWithChat",
        voice: "dealWithVoice",
        question: "dealWithQuestion",
        control: "dealWithControl",
        meeting: "dealWithMeeting",
        video: "dealWithVideo",
        controlCallback: "dealWithControlCallback",
        error: "dealWithError",
        online: "dealWithOnline",
        offline: "dealWithOffline",
        login:"dealWithLogin"
      },
      isStartRecord:false,
      totalPeople: 0,
      url: "",
      wss: null,
      isRecord: false,
      isPlaying: false, // 视频结束与否
      timer: null,
      player: {},
      bindData: {},
      msgCode: [-1, 0],
      revserveTime: {
        timer: null,
        time: 0,
      },
      canTalk: [-1, 0, 1, 2],
      showTips: false,
      textlist: ["会议开始，请主持专家发言", "", "讨论环节", "会议结束"],
      voiceList: [],
      btnvalue: "按住发言",
      isPlay: true,
      isReady: false,
      isTestMeeing: false,
      playTime: 0,
      eventsType: ["success", "primary"],
      tipBlock: {
        title: "提示",
        content: [""],
        btn: [],
        events: [
          // {
          //     event:null,
          //     data:null
          // }
        ],
        notice: "",
      },
      voiceTime: {
        maxCount: 59,
        time: null,
        startTime: 0,
        count: 0,
        isOver: false,
        lock: false,
      },
      curVoiceIndex: -1,
      meet_now_time: 0,
      meetTimer: null,
      voiceTimer: null,
      chatVoiceTime: 0,
      meeting_end: false,
      connectTime:0,
      isCanRecorder:false,
      stream:null,
      isSaving:false
    };
  },
  created() {
    console.log(window.devicePixelRatio)
    window.devicePixelRatio = 1
    this.id = this.$route.params.id;
    // this.startVoice()
  },
  watch:{
    "$store.state.isConnect":function(){
      let status = this.connectTime++ == 0 ? true : this.$store.state.isConnect
      !status ? this.showConnectTips() : this.closeConnectTips()
    }
  },
  computed: {
    revserveTimeShow() {
      try {
        console.log(this.info.start_time);
        if (!this.info.start_time) return "";
        let time = new Date(this.info.start_time.replace(/-/g, "/"));
        let millisecond = time.getTime();
        let spaceTime = millisecond - this.revserveTime.time;
        if (spaceTime < 0) return "";
        let day = Math.floor(spaceTime / (24 * 60 * 60 * 1000));
        let hour = Math.floor(spaceTime / (60 * 60 * 1000)) % 24;
        let minute = Math.floor(spaceTime / (60 * 1000)) % 60;
        let second = Math.floor((spaceTime / 1000) % 60);
        let str = "倒计时：";
        if (day) str = `${str}${day}天`;
        if (hour) str = `${str}${hour}小时`;
        if (minute) str = `${str}${minute}分`;
        if (second) str = `${str}${second}秒`;
        return str;
      } catch (error) {
        return "";
      }
    },
    getCountTime() {
      try{
        if (!this.info.meet_time || this.info.meet_time == "none") return "未开始";
        let timeText = this.info.meet_time.split(".")[0] || "";
        if (this.curStep == 3) return "已结束";
        let start_time = new Date(timeText.replace(/-/g, "/") || null).getTime();
        let meet_end_time =
          this.info.phase_time && this.info.phase_time[3]
            ? new Date(this.info.phase_time[3].replace(/-/g, "/")).getTime()
            : this.meet_now_time;
        let spaceTime = (meet_end_time || Date.now()) - start_time;
        // alert(timeText)
        let day = Math.floor(spaceTime / (24 * 60 * 60 * 1000));
        let hour = Math.floor(spaceTime / (60 * 60 * 1000)) % 24;
        let minute = Math.floor(spaceTime / (60 * 1000)) % 60;
        let second = Math.floor((spaceTime / 1000) % 60);
        return `${this.getDateText(day, 0)}${this.getDateText(hour, 1)}${this.getDateText(
          minute,
          2,
          true
        )}${this.getDateText(second, 3, true)}`;
      }catch(err){
        return ""
      }
    },
  },
  mounted() {
    this.getMeetingStatus()
  },
  methods: {
    isShowPlayer(){
      if(this.bindData.role == 0) return true
      return [1].includes(this.curStep)
    },
    isResetPlayer(){
      return [1,2,3,4,-1].includes(this.curStep)
    },
    initVoiceTime() {
      if (this.voiceTime.time) {
        clearTimeout(this.voiceTime.time);
        this.voiceTime.time = null;
      }
      if (this.voiceTime.isOver) {
        this.voiceTime.isOver = false;
        return;
      }
      let count = Math.floor((this.$tools.getTime() - this.voiceTime.startTime) / 1000);
      this.voiceTime.count = count;
      if (this.voiceTime.count >= this.getLimitTime()) {
        this.stopRecord(count, false);
        return;
      }
      this.voiceTime.time = setTimeout(() => {
        this.initVoiceTime();
      }, 100);
    },
    checkStartTime() {
      if (this.meetTimer) {
        clearTimeout(this.meetTimer);
        this.meetTimer = null;
      }
      if (this.meeting_end || this.curStep == 3) return;
      this.meetTimer = setTimeout(() => {
        this.meet_now_time = Date.now();
        this.checkStartTime();
      }, 1000);
    },
    getDateText(num, index, isCheck = false) {
      if (num < 0) num = 0;
      let unit = ["天", "时", "分", "秒"];
      return num || isCheck
        ? `${isCheck ? this.getFormatText(num) : num}${unit[index]}`
        : "";
    },
    getFormatText(text) {
      let str = text.toString();
      return str[1] ? str : `0${str}`;
    },
    startVoice() {
      if (this.voiceList.length == 0 || this.isRecord) {
        setTimeout(() => {
          this.startVoice();
        }, 1000);
      } else {
        let voice_id = this.voiceList.shift();

        this.playVoice({ index: -1, id: voice_id });
      }
    },
    reloadPage(){
      window.location.reload()
    },
    initVoiceCallback() {
      that = this;
      wxInfo.wx.onVoicePlayEnd({
        success: function (res) {
          that.startVoice();
        },
        error: function (res) {},
      });
    },
    getControlClass(index) {
      // if(this.isTestMeeing || this.curStep < 2){
      let stataus = this.curStep + 1 == index / 2;
      if (this.curStep < 1) {
        return this.curStep + 1 == index / 2;
      }
      if (this.curStep == 1) {
        return this.isRestrictTime(this.info.video_time) && stataus;
      }
      // 讨论开始后 三分钟之后才能点结束
      if (this.curStep == 2) {
        return this.isRestrictTime(3 * 60) && stataus;
      }
      return false;
    },
    getMeetingStatus(){
      let url = `${urls.meetingStatus}?live_id=${this.id}`
      axios.get(url,(res)=>{
        console.log(res.status)
        if(res.status){
          this.notStartMeetTips()
        }else{
          this.initWebsocket();
          this.initReserveTime();
        }
      })
    },
    notStartMeetTips(){
      this.tipBlock = {
        ...this.tipBlock,
        content:['您不能召开新的会议:','您有一场会议正在进行中，请先完成正在召开的会议'],
        notice:'',
        btn:['确定'],
        events: [
          {
            event: ()=>{
              this.$router.back()},
            type: "success",
            data: null,
          }
        ],
      };
      this.showTips = true;
    },
    
    /*
            spaceTime: 间隔时间  秒
        */
    isRestrictTime(spaceTime) {
      let time = this.info.phase_time[this.curStep]
        ? this.info.phase_time[this.curStep].replace(/-/g, "/")
        : null;
      let clickTime = new Date(time).getTime();
      return Date.now() >= clickTime + spaceTime * 1000 || this.isTestMeeing;
    },
    getRole(role) {
      return this.bindData.role === role;
    },
    getImg(img) {
      if (!img) return "";
      return `${img}`;
    },
    getTipText(index) {
      let text = ["进行中", "即将进行"];
      if (index % 2 == 1) return;
      let key = index / 2;
      if (this.curStep == key) {
        return text[0];
      } else {
        return this.curStep + 1 == key ? text[1] : key + 1;
      }
    },
    stopVoice() {
      this.curVoiceIndex = -1;
    },
    playVideo() {
      this.isPlay = true;
      this.$refs.playerItem.play();
    },
    playVoice(item) {
      let that = this;
      if(this.showVideo || this.curStep == 1){
        return;
        // this.voiceList.push(item)
        // return;
      }
      this.curVoiceIndex = item.index;
      if (this.voiceTimer) {
        clearTimeout(this.voiceTimer);
        this.voiceTimer = null;
      }
      if (item.index > -1 && this.info.chatList[item.index]) {
        this.info.chatList[item.index] = {
          ...this.info.chatList[item.index],
          showGray: true,
        };

      }
      this.voiceTimer = setTimeout(() => {
        this.curVoiceIndex = -1;
        this.showVideo = false
        if(this.voiceList.length > 0){
          this.playVoice(this.voiceList.shift())
        }
      }, (item.voice_length+4) * 1000 );
      this.showVideo = true
      this.changeURL(item.voice_id)

      // wxInfo.wx.downloadVoice({
      //   serverId: item.id,
      //   isShowProgressTips: 0,
      //   success: function (res) {
      //     var localId = res.localId;
      //     wxInfo.wx.playVoice({
      //       localId,
      //     });
      //   },
      //   fail: function (res) {
      //     that.stopVoice();
      //   },
      // });
    },
    changeURL(url) {
      let type;
      if(url){
        type = "video/mp4"
      }
      this.$nextTick(()=>{
        this.$refs.playerItem.switch(url ||this.url,type,this.bindData.role)

      })
    },
    screenshotHandler(obj) {
      if (!this.isPlaying) return;
      // this.config.poster = await upload.uploadBase64(obj.base64)
      this.config.poster = obj.base64;
      this.$nextTick(() => {
        this.screenshot(2);
      });
    },
    initReserveTime() {
      if (this.revserveTime.timer) {
        clearTimeout(this.revserveTime.timer);
        this.revserveTime.timer = null;
      }
      if (this.curStep > -1) return;
      this.revserveTime.time = Date.now();
      this.revserveTime.timer = setTimeout(() => {
        this.initReserveTime();
      }, 1000);
    },
    async touchstart() {
      if (this.canTalk.indexOf(this.curStep) == -1) {
        return;
      }
      this.isStartRecord = true;
      this.$refs.playerItem.play()
      await this.startRecord();
    },
    async touchend() {
      // if(this.curStep == -1) return;
      this.isStartRecord = false;
      await this.endRecord();
    },
    
    async touchcancel() {
      this.isStartRecord = false;
      // if(this.curStep == -1) return;
      this.isSaving = true
      await this.endRecord();
    },
    async clickRecord(){
      if(this.isRecord){
        await this.endRecord();
      }else{
        await this.startRecord();
      }
    },
    async endRecord() {
      this.isRecord = false;
      await this.stopRecord();
    },
    async startRecord(count=0) {
      if(count > 5) return;
      if(!recorder.isCanRecorder){
        return ;
      }
      this.isRecord = true;
      this.voiceTime.startTime = tool.getCurrTime();
      this.voiceTime.count = 0;
      let result = await recorder.startRecord(this.id)
      if(result.status == 500){
        this.startRecord((count += 1));
        return;
      }
      this.initVoiceTime() 
    },
    async stopRecord() {
      let voiceLength = Math.floor((Date.now() - this.voiceTime.startTime) / 1000);
      this.chatVoiceTime = voiceLength;
      let data = await recorder.stopRecord()
      console.log(data)
      if(data.status == 500){
        this.$tips.error({text:"录制错误，请重新录制"})
      }else{
        this.sendMessage({
            type: "chat",
            data: {
              content_type: 1,
              content: "",
              voice_id: data.data.url,
              voice_length: voiceLength,
            },
          });
      }
      this.btnvalue = "按住发言";
      // let that = this;
      // wxInfo.wx.stopRecord({
      //   success: function (res) {
      //     that.uploadVoice(res.localId, voiceLength);
      //   },
      //   fail: function (res) {
      //     // alert('fail')
      //   },
      // });
    },

    uploadVoice(localId, voiceLength) {
      let that = this;
      wxInfo.wx.uploadVoice({
        localId: localId,
        isShowProgressTips: 0,
        success: function (res) {
          that.sendMessage({
            type: "chat",
            data: {
              content_type: 1,
              content: "",
              voice_id: res.serverId,
              voice_length: voiceLength,
            },
          });
        },
        fail: function (res) {
          // alert('upload fail')
        },
      });
    },
    checkTime() {
      let startTime = Date.now();
      let spaceTime = 58000;

      this.timer = setInterval(() => {
        let curTime = Math.floor((spaceTime - (Date.now() - startTime)) / 1000);
        this.btnvalue = `${curTime}s - 松开发送`;
        if (
          Date.now() >= startTime + spaceTime ||
          this.canTalk.indexOf(this.curStep) == -1
        ) {
          this.endRecord();
        }
      }, 100);
    },
    initWebsocket() {
      let role = Number(String(this.$route.query.role).split('?')[0]) || 0
      websocket.initWebsocket(
        this.id,
        this.dealWithCallback,
        {
          openid:tool.getOpenid(),
          role:role
        }
      );
    },
    // 获取绑定数据
    dealWithCallback(data) {
      this[this.events[data.type]](data)
    },
    openTips() {},
    setStep(index) {
      // this.$refs.component.blurInput()
      // 0 开始 1 课程 2 讨论  3 结束
      if (index % 2) return;
      if (index / 2 <= this.curStep) return;
      if (index / 2 > this.curStep + 1) return;
      // this.curStep = index/2;
      if (index == 0) {
        this.seetMeet();
        return;
      }
      // if(this.curStep==1 && !this.isReady) return;
      let key = index / 2;
      this.setNextStep(key);
      // this.sendMessage({
      //     type:'control',
      //     data:{
      //         action:index/2
      //     }
      // })
    },
    errorTips(key) {
      this.tipBlock = {
        ...this.tipBlock,
        ...config.popups.operaOther[key],
        events: [
          {
            event: this.inNextStep,
            type: "primary",
            data: null,
          },
        ],
      };
      this.showTips = true;
    },
    delUserQuestion(key){
      this.tipBlock = {
        ...this.tipBlock,
        ...config.popups.operaOther[key],
        events: [
          {
            event: this.delQuestion,
            type: "success",
            data: "yes",
          },
          {
            event: this.delQuestion,
            type: "primary",
            data: null,
          },
        ],
      };
      this.showTips = true;
    },
    delQuestion(data){
      this.showTips = false;
      if(!data) return
      this.$refs.component.delQuestion()
    },
    setNextStep(index) {
      this.initTipsBlock();
      if (index == 2) {
        if (!this.isRestrictTime(this.info.video_time)) {
          this.errorTips("courseNotEnd");
          return;
        }
      }
      if (index == 3) {
        if (!this.isRestrictTime(3 * 60)) {
          this.errorTips("courseHaveMoreComment");
          return;
        }
      }

      this.tipBlock = {
        ...this.tipBlock,
        ...config.popups.operaBtn[index],
        events: [
          {
            event: this.inNextStep,
            type: "success",
            data: null,
          },
          {
            event: this.inNextStep,
            type: "primary",
            data: index,
          },
        ],
      };
      this.showTips = true;
      this.$refs.playerItem.setControl(false);
    },
    inNextStep(index) {
      this.showTips = false;
      if (index !== null) {
        this.$nextTick(() => {
          // if (index == 2) {
          //   // this.player.screenshot('video',false,index)
          //   // 测试会议不截图
          //   if (!this.isPlaying) {
          //     this.screenshot(index);
          //   } else {
          //     // 需求改动，视频播完后，不会有截图回调
          //     this.player.screenshot("video", false, index);
          //     // this.screenshot(index)
          //   }
          //   // let timeCount = this.isTestMeeing ? 0 : 2000;
          //   let timeCount = this.isTestMeeing ? 2000 : 2000;
          //   // 防止截图失败，点击操作后，两秒自动跳转下一步
          //   let that = this;
          //   setTimeout(() => {
          //     if (that.curStep != 1) return;
          //     that.screenshot(index, true);
          //     that.sendMessage({
          //       type: "control",
          //       data: {
          //         action: index,
          //         is_test: that.isTestMeeing,
          //         // is_test:true
          //       },
          //     });
          //   }, timeCount);
          // } else {
          //   this.screenshot(index);
          // }

          this.screenshot(index);
        });
      }
    },

    initTipsBlock() {
      this.tipBlock.events = [];
      this.tipBlock.btn = [];
      this.tipBlock.notice = "";
    },
    isIn30Minute() {
      let startTimeCount = new Date(this.info.start_time.replace(/-/g, "/")).getTime();
      return startTimeCount < Date.now() + 30 * 60 * 1000;
    },
    seetMeet() {
      this.initTipsBlock();
      let isIn = this.isIn30Minute();
      this.tipBlock = {
        ...this.tipBlock,
        ...config.popups.meetStatus,
        events: [
          {
            event: this.startMeet,
            type: "success",
            data: true,
          },
          {
            event: isIn ? this.startMeet : null,
            type: isIn ? "primary" : "info",
            data: false,
          },
        ],
      };
      this.showTips = true;
    },
    startMeet(type) {
      this.showTips = false;
      if (type) {
        this.isTestMeeing = true;
        this.$nextTick(() => {
          this.screenshot(0);
        });
      } else {
        let hour = new Date().getHours();
        if (22 <= hour || hour < 7) {
          this.errorTips("coursenotintime");
          return;
        }
        this.setNextStep(0);
      }
    },
    sendMessage(data) {
      console.log(data)
      websocket.sendMessage(data);
    },
    changeBanner(index) {
      // alert(JSON.stringify(this.bindData))
      this.msgStatus[index] = 0;
      
      this.$refs.playerItem.setMuted(true)
      this.curComponentsIndex = index;
    },
    loaded(){
      if(!this.showVideo) return
      this.$refs.playerItem.play(true);
    },
    initValue() {
      this.isPlay = true;
      this.isReady = false;
      this.url = "";
      try{
        this.$refs.playerItem && this.$refs.playerItem.pause();
      }catch(err){

      }
    },
    dealWithChat(item) {
      if (this.curComponentsIndex != 2 && item.data.length != 0) {
        this.msgStatus[2] = 1;
      }
      this.msgStatus = [...this.msgStatus];
      this.info.chatList = this.info.chatList.concat(item.data);
      if(item.data.content_type == 1){
        this.dealWithVoice({...item.data,index:this.info.chatList.length})
      }
    },
    dealWithVoice(item) {
      if (item.name == this.bindData.name) return;
      // this.playVoice({index:-1,id:item.data.voice_id});
      // this.voiceList.push({...item.data,index:this.info.chatList.length+1});
      // console.log(item.voice_id,item.index,this.info.chatList.length)
      // this.playVoice({...item})
    },
    dealWithQuestion(item) {
      if (this.curComponentsIndex != 0 && item.data.length != 0) {
        this.msgStatus[0] = 1;
      }
      this.msgStatus = [...this.msgStatus];
      this.info.chatList = [...this.info.chatList, ...item.data];
    },
    dealWithControl(item) {
      this.curStep = item.data.phase - 1;
      // 重置
      this.config.poster = "";
      this.info.meet_time = item.data.meet_time;
      this.info.phase_time = item.data.phase_time;
      console.log(this.curStep)
      if(this.bindData.role == 1 && !this.isShowPlayer()){
        this.changeURL(this.stream)
      }
      if(this.isResetPlayer()){
        this.isStartRecord = false;
      }
      if (this.curStep < 0) {
        // this.initValue()
        this.isTestMeeing = false;
        this.info.chatList = [];
        this.initReserveTime();
        return;
      }
      if (this.curStep == 0) {
        this.systemMsg(config.popups.startMeet, "red");
        return;
      }
      if (item.data.phase > 2) {
        this.isPlaying = false;
        this.initValue();
      }
      // 会议结束
      this.meeting_end = item.data.phase == 4;
      this.systemMsg(this.textlist[this.curStep]);
    },
    async screenshot(phase, status = false) {
      this.sendMessage({
        type: "control",
        data: {
          action: phase,
          is_test: this.isTestMeeing,
        },
      });

    },
    dealWithMeeting(item) {
      this.info = { ...this.info, ...item.info };
      wxInfo.shareMessage({
        title: this.info.title,
        description: `主持专家：${this.info.moderator_name}\n${this.info.start_time} `,
      });
      this.isTestMeeing = this.info.is_test;
      this.curStep = this.info.phase - 1;
      if (this.curStep == 1) this.isPlay = true;
      this.isPlaying = this.info.meeting_action == "play";
      // if(this.curStep == -1) this.showTips = true;
      document.getElementsByTagName("title")[0].innerHTML = this.info.title;
      this.checkStartTime();
    },
    dealWithLogin(item) {
      this.info.chatList = [...(this.info.chat || []), ...(item.data.chat || [])];
      for(let index in this.info.chatList){
        this.voiceList.push({index:Number(index),...this.info.chatList[index]})
      }
      this.bindData = {
        name: item.data.name,
        role: item.data.role,
      };
    },
    dealWithVideo(item) {
      let data = item.data;
      if (data.action == "play") {
        this.isPlaying = true;
        // let isMiddlePlay = Date.now()/1000 > data.play_time + 60  ;

        let timeText = this.info.phase_time[1]
          ? this.info.phase_time[1].replace(/-/g, "/").split(".")[0]
          : "";
        let isMiddlePlay =
          timeText && Date.now() > new Date(timeText).getTime() + 60 * 1000;
        this.validURL(data.url, isMiddlePlay);
        // this.systemMsg(`课件开始播放`)
      } else {
        this.isPlaying = false;
        this.initValue();
      }
    },

    validURL(url, isMiddlePlay = false) {
      let that = this;
      axios.getStream(url, (res) => {
        if (this.curStep >= 2) {
          this.playTime = 0;
          return;
        }
        if (this.playTime < config.maxPlayTimes && !isMiddlePlay) {
          if (this.playTime % config.spaceTime == 0) {
            let index = Math.floor(this.playTime / config.spaceTime);
            config.popups.video[index] && this.systemMsg(config.popups.video[index]);
            if (this.playTime == 0)
              setTimeout(() => {
                this.systemMsg(config.popups.startVideo);
              }, 7000);
          }
          if (res.isErrorStatus) this.playTime = 0;
          this.playTime++;
          setTimeout(() => {
            that.validURL(url);
          }, 1000);
        } else {
          this.systemMsg(config.popups.endVideo, "red");
          this.playTime = 0;
          this.isPlay = false;
          this.isReady = true;
          this.url = url;
          this.changeURL();
          // this.systemMsg(`课件开始播放`)
        }
      });
    },
    // validURL(url){
    //     let that = this;
    //     axios.getStream(url,(res)=>{
    //         if(this.curStep >=2) return;
    //         if(res.isErrorStatus){
    //             setTimeout(()=>{
    //                 that.validURL(url)
    //             },1000)
    //         }else{
    //             this.isPlay = false;
    //             this.isReady = true;
    //             this.url = url;
    //             this.changeURL()
    //             this.systemMsg(`课件开始播放`)
    //         }
    //     })
    // },
    dealWithError(item) {
      tips.error({ text: item.data.message });
    },
    showConnectTips(){
      this.tipBlock = {
        ...this.tipBlock,
        content:['您的网络已中断，请切换至稳定的网络后，点击“重新连接”'],
        notice:'',
        btn:['重新连接'],
        events: [
          {
            event: this.reloadPage,
            type: "success",
            data: null,
          }
        ],
      };
      this.showTips = true;
    },
    closeConnectTips(){
      this.showTips = false;
    },
    dealWithControlCallback(item) {
      if (!item.data.status) {
        this.errorTips("courseHaveMoreComment_end");
      }
    },
    dealWithOnline(item) {
      let text = "",
        color = undefined;
      let data = item.data;
      this.changeStatus(data, true);
      if (data.role == config.auth[1]) {
        color = "red";
        text = `${data.name}${config.popups.academic}`;
        if(this.bindData.role == data.role){
          recorder.init((stream)=>{
            this.stream = recorder.stream;
            this.isCanRecorder = true
            this.changeURL(this.stream)
          })
        }
      } else text = data.name;


      if (this.msgCode.indexOf(this.curStep) > -1 && data.role != -1) {
        this.systemMsg(`${text}${config.popups.infloor}`, color);
      }
      this.totalPeople = data.online_count;
    },
    dealWithOffline(item) {
      let text = "";
      let data = item.data;
      this.changeStatus(data, false);
      if (data.role == config.auth[1]) text = `${data.name}${config.popups.academic}`;
      else text = data.name;
      // this.systemMsg(`${text}${config.popups.outfloor}`)
      this.totalPeople = data.online_count;
    },
    changeStatus(data, status) {
      if (data.role == 1) {
        this.info.moderator_online = status;
      }
      if (data.role == 0) {
        this.info.admin_online = status;
      }
    },
    systemMsg(text, color = "gray") {
      if (!text) return;
      let msg = {
        name: "系统消息",
        content: text,
        color,
      };
      this.info.chatList.push(msg);
    },
    stopPre() {},
    hidetips() {
      this.showTips = false;
    },
  },
  beforeDestroy() {
    websocket.close();
  },
  components: {
    liveInfo,
    liveChat,
    liveQuestion,
    myPlayer,
    VoicePlayer,
  },
};
</script>
<style scoped>
.live {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.live .live-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.live .live-meet-time {
  padding: 1vw 5.46vw;
  display: flex;
  font-size: 3.2vw;
  text-align: center;
  color: #666;
  font-weight: 400;
}
.live .videocont {
  height: auto;
  width: 100%;
  position: relative;
}
.live .video_main_container {
  min-height: 53.33vw;
  max-height: 53.33vw;
  width: 100%;
}
.video_main_container video {
  height: 53.33vw !important;
  width: 100%;
}
.live .live-stage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-size: 100% 100% !important;
  width: 100%;
  height: 100%;
  /* background:#000; */
  color: #813048;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 12vw 12vw 2vw 12vw;
  text-align: center;
  line-height: 8vw;
  font-weight: 700;
  border-bottom: 1px solid #ececec;
}
.live .tipscontent {
  position: absolute;
  left: 13vw;
  bottom: 10vw;
  height: 8vw;
  line-height: 4vw;
  font-size: 2.8vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.live .tipstitle {
  font-size: 4.2vw;
  line-height: 6vw;
}
.live .operacont {
  flex: 25.8vw 0 0;
  padding: 0 5.46vw 3vw;
}
.live .operastatus {
  widows: 100%;
  height: 3.33vw;
  margin-top: 3.6vw;
  margin-bottom: 1vw;
  display: flex;
  justify-content: space-between;
  font-size: 3.33vw;
  text-align: center;
  color: #666;
  font-weight: 400;
}

.live .operatext:first-child {
  text-align: left;
}
.live .operatext:last-child {
  text-align: right;
}

.live .operatext {
  flex-grow: 1;
  height: 100%;
  /* font-size:4vw;; */
  color: #5c5c5c;
  font-size: 3.2vw;
}
.live .roundstatusimg {
  width: 2vw;
  height: 1.6vw;
  margin-right: 2vw;
}
.live .roundstatus {
  display: inline-block;
  width: 1.04vw;
  height: 1.04vw;
  background: #9aad2a;
  border-radius: 0.52vw;
  margin-right: 2vw;
}
.live .operabtngroupmember {
  height: 12.53vw;
  margin-top: 2.26vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.66vw;
}

.live .operabtngroupmember .curactive {
  background: #bc6891;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  -webkit-animation: breathe 1s ease-in-out infinite alternate;
}
@-webkit-keyframes breathe {
  0% {
    opacity: 1;
    /* border:1px solid rgba(129,48,75,1); */
    box-shadow: 0 1px -15px rgba(129, 48, 75, 1);
  }
  100% {
    opacity: 1;
    /* border:1px solid rgba(129,48,75,1); */
    box-shadow: 0 1px 30px rgba(129, 48, 75, 1);
  }
  /* 100% {
        opacity:1;
        border:1px solid rgba(129,48,75,1);
	    box-shadow:0 1px 30px rgba(129,48,75,1);
    } */
}
.live .operabtngroupmember > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.live .operabtngroupmember > div {
  width: 10.66vw;
  height: 10.66vw;
}
.live .operabtngroupmember > div:nth-child(2n + 1) {
  background: #ccc;
  border-radius: 5.33vw;
}
.live .operabtngroupmember .beforestep {
  background: #ccc !important;
}
.live .operabtngroupmember .curstep {
  /* width:12.53vw;
    height:12.53vw;
    border-radius:6.27vw !important; */
  background: #81304b !important;
}
.live .operabtngroupmember .nextstep {
  background: #bc6891 !important;
}
.live .operabtngroupmember .operalinearrow {
  background: none !important;
  height: 1.6vw !important;
}
.live .operabtngroupmember .operalinearrow > div {
  width: 100%;
  height: 100%;
  background: url(../../../assets/images/opa-arrow.png);
  background-size: 100% 100%;
}
.live .operabtngroupmember .operabtnradius > div {
  width: 6.4vw;
  height: 6.4vw;
}
.live .operabtngroupmember .operabtnradius:nth-child(1) > div {
  background: url(../../../assets/images/play.png);
  background-size: 100% 100%;
}
.live .operabtngroupmember .operabtnradius:nth-child(3) > div {
  background: url(../../../assets/images/pause.png);
  background-size: 100% 100%;
}
.live .operabtngroupmember .operabtnradius:nth-child(5) > div {
  background: url(../../../assets/images/discuss.png);
  background-size: 100% 100%;
}
.live .operabtngroupmember .operabtnradius:nth-child(7) > div {
  background: url(../../../assets/images/stop.png);
  background-size: 100% 100%;
}

.live .operabtngroupguest {
  height: 12.53vw;
  margin-top: 1.26vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.66vw;
  font-size: 3vw;
  font-weight: 500;
}
.live .operabtngroupguest > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.live .operabtngroupguest > div:nth-child(2n + 1) {
  width: 16.33vw;
  height: 8vw;
  border-radius: 1.04vw !important;
}
.live .operabtngroupguest > div:nth-child(2n) {
  width: 5.9vw;
  height: 8vw;
  border-radius: 1.04vw !important;
}
.live .operabtngroupguest > div:nth-child(2n + 1) {
  border: 4px solid #ccc;
  color: #ccc;
}
.live .operabtngroupguest .beforestep {
  color: #ccc !important;
  border-color: #ccc !important;
}
.live .operabtngroupguest .curstep {
  color: #81304b !important;
  /* width:14.6vw !important;
    height:9.4vw !important; */
  border-color: #81304b !important;
}
.live .operabtngroupguest .curstep > div {
  /* width:4.8vw;
    height:4.8vw; */
  /* background:url(../../../assets/images/sure.png); */
  /* background-size:100% 100%; */
}
.live .operabtngroupguest .nextstep {
  border-color: #bc6891 !important;
  color: #bc6891 !important;
}
.live .operabtngroupguest .operalinearrow {
  height: 1.6vw !important;
}
.live .operabtngroupguest .operalinearrow > div {
  width: 100%;
  height: 100%;
  background: url(../../../assets/images/opa-arrow.png);
  background-size: 100% 100%;
}
.live .operabtngroupguest .operabtnradius:nth-child(2n) {
  border: 4px solid #81304b;
}
.live .operabtn {
  width: 12.8vw;
  height: 12.8vw;
}
.live .arrow {
  width: 10vw;
  height: 1.32vw;
}
.live .operabtntextmember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.04vw;
  height: 3.73vw;
  padding: 0 0.66vw;
  color: #ccc;
}
.live .operabtntextmember > div {
  width: 10.66vw;
}
.live .operabtntextmember .cursteptext {
  /* width:12.53vw; */
  text-align: center;
}
.live .operabtntextmember .btntext {
  /* flex-grow: 1; */
  color: #ccc;
  font-size: 3.73vw;
  font-weight: 700;
  text-align: center;
}

.live .operabtntextmember .arrowtext {
  flex-grow: 1;
}
.live .operabtntextguest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.04vw;
  height: 3.73vw;
  padding: 0 0.66vw;
  color: #ccc;
}
.live .operabtntextguest > div:nth-child(2n + 1) {
  width: 13.33vw;
}
.live .operabtntextguest > div:nth-child(2n) {
  width: 8.9vw;
}
.live .operabtntextguest .cursteptext {
  width: 14.6vw !important;
}
.live .operabtntextguest .btntext {
  /* flex-grow: 1; */
  color: #ccc;
  font-size: 3.73vw;
  font-weight: 500;
  text-align: center;
}

.live .operabtntextguest .arrowtext {
  flex-grow: 1;
}
.live .beforetextstep {
  color: #ccc !important;
}
.live .curtextstep {
  color: #81304b !important;
}
.live .nexttextstep {
  color: #bc6891 !important;
}
.live .bannercont {
  flex: 9.6vw 0 0;
  display: flex;
  align-items: center;
  font-size: 4.13vw;
  color: #555;
  font-weight: 700;
  width: 100%;
}
.live .bannercont div {
  background: #ececec;
  flex: 1 0 0;
  text-align: center;
  height: 10.4vw;
  line-height: 10.4vw;
  margin-right: 0.64vw;
}

.live .bannercont .meetbannertext {
  position: relative;
}
.live .bannercont .meetredtips {
  position: absolute;
  width: 15px;
  height: 15px;
  background: red;
  right: -15px;
  top: -5px;
  display: inline-block;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-animation: pointred 0.5s ease-in-out infinite alternate;
}
@-webkit-keyframes pointred {
  0% {
    transform: scale(0.5, 0.5);
    box-shadow: 0 1px 1px rgba(255, 0, 0, 1);
  }
  100% {
    transform: scale(1.5, 1.5);
    box-shadow: 0 1px 10px rgba(255, 0, 0, 1);
  }
}
.live .bannercont div:last-child {
  margin: 0;
}
.live .bannercont .active .meetborderdiv {
  display: inline-block;
  height: 100%;
  line-height: 10.4vw;
  border-bottom: 4px solid #81304b;
  color: #81304b;
}
.live .blockcont {
  /* height:58.04vw; */
  /* height:76.04vw; */
  flex: 1 0 0;
  /* overflow: hidden; */
  position: relative;
  z-index: 2;
}
.liveblockscroll {
  /* overflow: auto; */
}
.liveblockhidden {
  overflow: hidden;
}
.live .voiceblock {
  flex: 18.4vw 0 0;
  padding: 2.8vw 4.8vw;
  width: 100%;
}
.live .voiceblock .thebutton {
  height: 12.8vw;
  width: 100%;
  background: #81304b;
  border-radius: 12.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.live .meetvoiceicon {
  font-size: 6vw;
  vertical-align: middle;
}
.live .voiceblock,
.live .voiceblock * {
  user-select: none;
  -webkit-user-select: none;
}
.live span,
.live img,
.live i {
  user-select: none;
  -webkit-user-select: none;
}
.live .voiceblock .thebutton {
  height: 9.33vw;
  border-radius: 6.4vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  /* font-weight: 700; */
  user-select: none;
  -webkit-user-select: none;
  margin: none;
  padding: 0 !important;
  position: relative;
  height: 100%;
}
.live .meettopshow {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  opacity: 0;
}
.live .voiceblock .thebutton > span img,
.live .voiceblock .thebutton > span span {
  vertical-align: middle;
}
.live .voiceicon {
  width: 4.26vw;
  height: 4.26vw;
}
.live .green {
  color: #9aad2a;
}
.live .red {
  color: #81304b;
}
.live .nostart .thebutton,
.live .nostart .thebutton:hover {
  background: #999;
  border: 1px solid #999;
}
.live .cordcolor .thebutton,
.live .cordcolor .thebutton:hover {
  background: #9aad2a;
  border: 1px solid #9aad2a;
}
.live .nocordcolor .thebutton,
.live .nocordcolor .thebutton:hover {
  background: #81304b;
  border: 1px solid #81304b;
}
.live .cordcolor .thebutton {
  background: #9aad2a;
  border: 1px solid #9aad2a;
}

.live .tipmeetings {
  position: absolute;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.live .tipsmeetcontainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 10%;
}
.live .tipsmeetdiv {
  width: 100%;
  border: 1px solid #81304b;
  padding: 0 0 4vw;
  background: #fff;
  border-radius: 2vw;
  position: relative;
}
.live .tipsmeettitle {
  padding: 2vw;
  margin-top: -1px;
  background: #813048;
  width: 100%;
  color: #fff;
  border-top-right-radius: 2vw;
  border-top-left-radius: 2vw;
}
.live .tipsmeetcontent {
  padding: 4vw;
  display: flex;
  min-height: 28vw;
  justify-content: center;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-bottom: 4vw;
}
.live .tipsmeetcontent .noticetext {
  color: #000;
  text-align: center;
  font-size: 4vw;
}
.live .showstartime {
  position: absolute;
  /* bottom:8vw; */
}
.live .showreservetime {
  /* position: absolute;
    bottom:4vw; */
  /* background: red; */
}

.live .showreservetime {
  /* line-height: 5.2vw; */
}
.live .tipmeetdesc {
  padding-bottom: 2vw;
  text-align: center;
}
.live .tipsmeetbtngroup {
  width: 100%;
  display: flex;
  justify-content: center;
}
.live .tipsmeetbtngroup > div {
  width: 50%;
  padding: 0 2vw;
  display: flex;
  justify-content: center;
}

.live .discusstext {
  font-size: 8vw;
}
.live .tipsstatustext {
  text-indent: 4vw;
}
.live .tiptrueprimary {
  color: #81304b;
}
.live .tipfalsesuccess {
  color: #9aad2a;
}

.live .live-stage img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 53.33vw;
}
.live_reconnect{
  position: fixed;
  left:0;
  top:0;
  z-index:3000;
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background:rgba(6, 6, 6, 0.7)
}
.live_reconnect_main{
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
  padding:0 8vw;
}
.live_reconnect_main span{
  text-align: center;
  color:red;
}
.live_reconnect_main button{
  margin-top:4vw;
}
</style>
