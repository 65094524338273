<template>
    <div class='live-chat'>
        <div class='chat-cont'>
            <div class='chat-area'  ref='chatScroll'>
                <div class='chatscroll_container'>
                    <div class='chatscroll_li'  v-for='(item,index) in this.info.chatList' :key='index' :class='item.role == 1?"red":"purple"'>
                        <div>{{item.name}}:&nbsp;&nbsp;</div>
                        <div>{{item.content}}</div>
                    </div>
                </div>
            </div>
            <div class='chat-btn'>
                <div class='comment-icon'>
                    <i class='el-icon-s-comment'></i>
                </div>
                <div class='sendvalue'>
                    <el-input :placeholder="placeholderText[person.role]" v-model="msg" ref="msgInput" @focus='focusInput' ></el-input>
                </div>
                <div class='sendbtn'  @click='talk' id='sendbtn'>
                    <el-button>{{btntext[person.role]}}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BSscroll from 'better-scroll'
import tool from '@/common/tool'
export default {
    name:'live-chat',
    props:{
        info:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        person:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data:()=>{
        return {
            msg:'',
            persionInfo:tool.getStorage('info'),
            scroll:null,
            placeholderText:['输入后按回车发送','输入后按回车发送','输入后按回车发送'],
            btntext:['发送给主持专家','发送给管理员','发送'],
        }
    },
    updated() {
        this.$nextTick(() => {
            this.initScroll();
            console.log(11111111)
            this.scroll.scrollTo(0,this.scroll.maxScrollY)
        });
    },
    created(){
        this.initEnter()
        this.initScroll();
    },
    methods:{
        initEnter(){
            var that = this;
            document.onkeydown = function(e) {
                var key = window.event.keyCode;
                if (key == 13) {
                    that.talk();
                }
            }
        },
        talk(){
            if(!this.msg) return;
            this.$emit('talk',{
                type:'chat',
                data:{
                    content_type:0,
                    content:this.msg,
                    voice_id:'',
                    voice_length:0
                }
            })
            this.msg = '';
        },
        initScroll(){
             this.$nextTick(()=>{
                if(!this.scroll){
                    this.scroll = new BSscroll(this.$refs.chatScroll,{
                        click:true,
                        bounce:false,
                    });
                }else{
                    this.scroll.refresh();
                }
            })
        },
        focusInput(){
            console.log(1111)
        },
        loseFocus(){
            this.$refs.msgInput.blur();
        }
    }
}
</script>
<style>
.live-chat{
    height: 100%;
    padding: 2vw 2.4vw 0 2.4vw;;
}
.live-chat .chat-area{
    overflow: hidden;
    min-height: 37.04vw;
    margin-bottom: 1.04vw;
    flex:1
}
.live-chat .chatscroll_li{
    font-size: 3.2vw;
    font-weight:600;
    min-height: 4vw;
    line-height: 4vw;
    display: flex;
}
.live-chat .chatscroll_li div:first-child{
    min-width:8vw;
}
.live-chat .chatscroll_li div:last-child{
    flex:1;
    word-break: break-all;
}
.live-chat .chat-cont{
    background:#ececec;
    height:100%;
    position: relative;
    padding: 3vw 4vw;
    box-sizing: border-box;
    border:2px solid #959595;
    border-radius: 2vw;    
    display: flex;
    flex-direction: column;
}
.live-chat  .chat-btn{
    height: 12vw;
    position: relative;
    display:flex
}
.live-chat  .sendvalue{
    width:100%;
    flex:1;
}
.live-chat  .sendvalue input{
    border:1px solid #a60852;
    /* padding-right: 18vw; */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
}
.live-chat  .sendbtn>button{
    /* position: absolute;
    right:0;
    top:0; */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.live-chat .sendbtn button:hover{
    background:#fff;
}
.live-chat .sendbtn button:active{
    background:#81304B;
    color:#fff;
}
.live-chat .sendbtn button{
    color:#a60852;
    border:1px solid #a60852;
}
/* .live-chat .sendbtn button:hover{
    background:#fff;
} */

.live-chat .red{
    color:#ff0920;
}
.live-chat .purple{
    color:#81304B;
}
</style>