<template>
    <div class='live-info' ref='liveinfo' >
        <div class="live-main" >
            <div class='liveinfotitle'>
                <div class='infomation'>
                    <span class='infokey'>会议讲题</span>
                    <span class='infovalue'>{{getTitle()}}</span>
                </div>
            </div>
            <div class='liveinfotitle meettimeline'>
                <div class='infomation'>
                    <span class='infokey'>会议时间</span>
                    <span class='infovalue'>{{info.start_time}}</span>
                </div>
            </div>
            <div class='liveinfocontent meettimeline'  >
                <div class='infomation'>
                    <span class='infokey'>主持专家<br/>介绍</span>
                    <span class='infovalue persioninfo'>
                        <img :src="`${info.moderator_avatar}`" v-if='info.moderator_avatar' alt="暂无图片">
                        <div class='liveinfoname'>
                            <span class='live-cur-name'>{{info.moderator_name}}</span>
                            <!-- <span class='space-point'></span> -->
                        </div>
                        <div class='liveinfoname'>
                            <span class='live-in-hospital'>{{info.moderator_hospital}}</span>
                            <span class='space-point'></span>
                            <span class='live-in-hospital'>{{info.department}}</span>
                        </div>
                        <div class='liveinfodescription'>{{info.moderator_description}}</div>
                        <!-- <div class='livewatchmore'>
                            <span>查看更多</span>
                            <i class='el-icon-arrow-down'></i>
                        </div> -->
                    </span>
                </div>
            </div>
            <!-- <div class='liveinfocontent'  >
                <div class='infomation'>
                    <span class='infokey'>讲者介绍</span>
                    <span class='infovalue persioninfo'>
                        <img :src="`${info.speaker_avatar}`" alt="暂无图片">
                        <div class='liveinfoname'>
                            <span class='live-cur-name'>{{info.speaker_name}}</span>
                            <span class='space-point'></span>
                            <span class='live-in-hospital'>{{info.speaker_hospital || '暂无'}}</span>
                        </div>
                        <div class='liveinfodescription' v-html='info.speaker_description'>{{info.speaker_description}}</div>
                    </span>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import config from '@/common/config';
import BSscroll from 'better-scroll'
export default {
    name:'live-info',
    props:{
        info:{
            type:Object,
            dafault:{}
        },
    },
    data:()=>{
        return {
            config,
            scroll:null,
            data:{},
        }
    },
    created(){
        this.$nextTick(()=>{
            this.initScroll()
        })
    },
    methods:{
        getTitle(){
            let reg = /[\uff08\(]{1}[\u3400-\u9fff]+[\uff09\(]{1}/g
            // return this.info.topic.replace(reg,'') 
            return this.info.topic || ''
        },
        initScroll(){
            console.log(this.info)
            this.$nextTick(()=>{
                if(!this.scroll)  this.scroll = new BSscroll(this.$refs.liveinfo,{click:true})
                else this.scroll.refresh()
            })
        },
    }
}
</script>
<style scoped>
.live-info{
    height:100%;
    overflow: hidden;
}

.live-main{
    padding:6.26vw 6.26vw 0;
}
.live-info .liveinfotitle{
    min-height:6.13vw;
}
.live-info .meettimeline{
    border-bottom: 1px solid #ececec;
}
.live-info .liveinfocontent{
    padding-top:6.26vw;
}
.live-info .persioninfo>img{
    width:18vw;
    height:24vw;
    /* height:23.6vw; */
    min-width: 18vw;
    min-height: 24vw;
    max-width: 18vw;
    max-height: 24vw;
}
.live-info .liveinfoname{
    margin-top:1.66vw;
    color:#000;
    font-size:3.56vw;
    display: flex;
    align-items: center;
}
.live-info .space-point{
    display: inline-block;
    width:0.66vw;
    height:0.66vw;
    border-radius:.33vw;
    background:#999;
    margin:0 2.26vw;
}
.live-info .live-in-hospital{
    font-size:2.93vw;
    color:#999;
}
.live-info .liveinfodescription{
    padding:1.86vw 0;
    font-size:3.33vw;
    color:#000;
    white-space: pre-wrap;
    line-height: 4.06vw;
    word-break: break-all;
}
.live-info .livewatchmore{
    color:#78354A;
    font-size:3.33vw;
    line-height: 7.33vw;
}
.live-info .livewatchmore>i{
    width:.66vw;
    height:.66vw;
}
.live-info .infomation {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: 2vw;
    font-size:3.66vw;
    font-weight: 500;
}
.live-info .infomation span.infokey {
    width: 18vw;
    text-align: left;
    font-size:3.86vw;
    color:#666;
    margin-right: 4vw;
}
.live-info span.infovalue {
    flex:1;
    text-align: left;
    color:#333;
}
</style>