<template>
    <div class='live-question' @click.stop='blurInput'>
        <div class='question-cont'>
            <div class='question-area'  ref='chatListcroll'>
                <div class='chatListcroll_container'>
                    <div class='chatListcroll_li'  v-for='(item,index) in info.chatList' :key='index' :class='`${getClass(item)} ${curIndex == index ?"curvoice":""}`'>
                        <div :class='item.content_type == 1?"voiceTitleText":""'>{{item.name}}{{auth[getIndex(item)]}}:&nbsp;&nbsp;</div>
                        <div v-if='item.content_type != 1'>{{item.content}}</div>
                        <div v-if='item.content_type == 1' @click='playVoice(item,index)' class='voiceContainer voicecontent' :style='{"background-image":`url(${getBackground(index,item)})`,"background-size":"100% 100%"}' >
                            <!-- <div class="voicePlay " :class='curIndex == index?"voicePlayer":""'>
                                <div class="voiceContener outsidediv" ></div>
                                <div class="voiceContener"></div>
                                <div class="voiceContener"></div>
                            </div> -->
                            <!-- <div class='voiceVoiceText'>"</div> -->
                        </div>
                        <div v-if='item.content_type == 1 && !item.showGray && person.role == 0' class='voiceTitleTextPoint'><span></span></div>
                        <!-- <div v-if='item.content_type == 1' class='voiceTitleText'>{{item.voice_length}}"</div> -->
                    </div>
                </div>
            </div>
            <div class='question-btn'>
                <!-- <div class='comment-icon'>
                    <i class='el-icon-s-comment'></i>
                </div> -->
                <div class='sendvalue'  @click.stop=''>
                    <el-input placeholder="输入后按回车发送" v-model="msg" @focus='focusInput'  ref='commentinput'></el-input>
                </div>
                <div class='fastsendbtn' @click.stop='talk()' :class='{"question_btn_radius":person.role == 0}'>
                    <el-button>发送</el-button>
                </div>
                <div class='sendbtn' @click.stop='setShowMessage()' v-if='person.role == 0'>
                    <el-button>
                        快捷
                        <div><i class="el-icon-chat-dot-round"></i></div>
                    </el-button>
                    <div class='fast-message-list' ref='fastmessagelist' v-show='showFastMessage' @click.stop='showFastMessage=false'>
                        <!-- <div class='fast-message-main' @touchstart="touchstart"  @touchmove="touchmove" @touchend="touchend" @touchcancel="touchend" > -->
                        <div class='fast-message-main'>
                            <div class="fast-message-content addquestion-text" @click="addUserQuestion"><i class="el-icon-circle-plus-outline"></i><span>添加我的自定义问题</span></div>
                            <div  v-for='(item,index) in questionList' :key='index'>
                                <!-- <div class="fast-message-content" :class='{"curSelectTab":selectTab.index === index}'> -->
                                <div class="fast-message-content">
                                    <div class="fast-message-text" @click="sendQuestion(item.question)">{{index+1}}、{{item.question}}</div>
                                    <div class="fast-message-del"><div class="fast-message-delicon" @click="delUserQuestion(item.id)" v-if="item.id">—</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="live-addquesthion-background" v-if="showAddQuestion" @click="closeAddQuestion">
            <div class="live-addquesthion-main">
                <div class="live-addquesthion-block" @click.stop="">  
                    <div class="live-addquesthion-titel">添加我的自定义问题</div>
                    <div class="live-addquesthion-input" >
                        <el-input
                            type="textarea"
                            :rows="2"
                            :maxlength="50"
                            show-word-limit
                            placeholder="请输入问题(最多50字)"
                            :autosize="{ minRows: 5, maxRows: 5}"
                            v-model="data.question">
                        </el-input>
                    </div>
                    <div class="live-addquesthion-buttongroup">
                        <el-button type="primary" size="mini" @click.stop="submitQuestion">提交</el-button>
                        <el-button size="mini" @click="closeAddQuestion">取消</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BSscroll from 'better-scroll'
import tool from '@/common/tool'
import urls from "@/common/urls"
import tips from "@/common/tips"
import axios from "@/common/axios"
const images = {
    admin:{
        playend:require('@/assets/images/voice/voice_admin.png'),
        default:require('@/assets/images/voice/voice_admin_none.png'),
        play:require('@/assets/images/voice/voice_admin_play.gif'),
    },
    guest:{
        playend:require('@/assets/images/voice/voice_guest.png'),
        default:require('@/assets/images/voice/voice_guest_none.png'),
        play:require('@/assets/images/voice/voice_guest_play.gif'),
    }
}
export default {
    name:'live-question',
    
    props:{
        info:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        person:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        curIndex:{
            type:Number,
            default:-1
        },
    },
    data:()=>{
        return {
            msg:'',
            questionList:[],
            scroll:null,
            fastMesssageScroll:null,
            auth:['',' 教授（主持专家）'],
            config:{
                presetThanking:[
                    {question:"很荣幸能参与本次线上学习！"},
                    {question:"期待您的精彩授课！"},
                    {question:"很高兴参与本次课程学习，期待与各位同道一起交流探讨！"},
                    {question:"谢谢！"},
                    {question:"谢谢老师的解答！"},
                    {question:"感谢您的精彩讲解！"},
                    {question:"下次再见！"},
                    {question:"期待再次和大家学习交流！"},
                    {question:"通过本次学习受益良多，谢谢大家！"},
                ]
            },
            id:0,
            data:{
                question:'',
                meeting_live_id:''
            },
            questionID:"",
            curSelectTab:'',
            showFastMessage:false,
            showAddQuestion:false,
            selectTab:{
                index:'',
                startTop:0,
            }
        }
    },
    updated() {
        // let  chatNumber  = this.$store.state.chatNumber;
        // if(chatNumber < this.info.chatList.length){
        //     this.$nextTick(() => {
        //         this.initScroll();
        //     });
        //     this.$store.commit('setState',{chatNumber:this.info.chatList.length})
        // }
        
        this.initScroll();
        if(this.person.role == 0){
            this.initFastScroll();
        }
    },
    created(){
        this.id = this.$route.params.id;
        this.getUserQuestions()
        this.$store.commit('setState',{chatNumber:0})
        this.initScroll();
    },
    watch:{
        'info.chatList':function(){
            this.initScroll();
        }
    },
    mounted(){
    },
    methods:{
         getUserQuestions(){
            let url = tool.getURL(urls.question.list,{meeting_live_id:this.id})
            let i ="";
            axios.get(url,(res)=>{
                // let arr = res.data.reverse()
                for(let ind in res.data){
                    if(!(res.data[ind]["id"])){
                        i = Number(ind)
                        break;
                    }
                }
                let definedQues =  res.data.splice(0,i) //definedQues预设问题
                this.questionList = res.data.concat(this.config.presetThanking).concat(definedQues)
            })
        },
        delUserQuestion(id){
            this.questionID = id
            this.$emit("tipsError")
        },
        delQuestion(){
            let url = tool.getURL(urls.question.delete,{id:this.questionID})
                axios.delete(url,(res)=>{
                    if(!res.errcode){
                        this.questionID = ''
                        this.getUserQuestions()
                    }
                })
        },
        addUserQuestion(){
            this.showAddQuestion = true
        },
        formData(){
            if(this.data.question == "")
            {
                tips.error({ text: "问题不能为空" });
                return false
            }
            return true
        },
        submitQuestion(){
            if(!this.formData()){
                return false
            }
            let url = urls.question.creat;
            this.data.meeting_live_id = this.id;
            axios.post(url,this.data,(res)=>{
                if(!res.errcode){
                this.data.question = ""
                this.closeAddQuestion()
                this.getUserQuestions()
                }
            })
        },
        closeAddQuestion(){
            this.showAddQuestion = false
        },
        sendQuestion(question){
            this.talk(question)
        },
        getVoice(id){
            let list = tool.getStorage('voiceList') || [];
            return list.indexOf(id) > -1
        },
        getBackground(index,item){
            let cfg  = this.person.role == 1 ? images.admin : images.guest;
            let img = this.curIndex == index ? cfg.play :  item.showGray ? cfg.playend : cfg.default;
            return img;
        },
        getIndex(item){
            if(this.info.openid){
                return this.isSelf(item) ? 1:0;
            }
            return  Number(item.name == this.person.name && this.person.role == 1)
        },
        isSelf(item){
            return this.info.openid === item.openid 
        },
        initEnter(){
            var that = this;
            document.onkeydown = function(e) {
                var key = window.event.keyCode;
                if (key == 13) {
                    that.talk();
                }
            }
        },
        playVoice(item,index){
            if(this.curIndex != -1 || this.curIndex == index) return; 
            this.$emit('playVoice',{...item,index})
        },
        blurInput(){
            this.showFastMessage = false;
            this.$refs.commentinput.blur();
        },
        setShowMessage(){
            this.showFastMessage = !this.showFastMessage
        },
        focusInput(){
            this.showFastMessage = false;
            this.initEnter();
        },
        talk(content=''){
            console.log(this.msg)
            if(!this.msg && !content) return;
            this.$emit('talk',{
                type:'chat',
                data:{
                    content_type:0,
                    content:  content || this.msg,
                    voice_id:'',
                    voice_length:0
                }
            })
            if(!content) this.msg = '';
            this.showFastMessage = false;
            if(content){
                this.blurInput();
            }
        },
        getClass(item){
            if(item.color) return item.color;
            if(item.openid == this.info.openid) return "purple"
            if(this.person.role == 0){
                return item.name == this.person.name?'blue':'';
            }else{
                if(this.info.openid){
                    return this.isSelf(item) ? 'purple':'';
                }
                return item.name == this.person.name && this.person.role == 1? 'purple':'';
            }

        },
        initScroll(){
             this.$nextTick(()=>{
                if(!this.scroll){
                    this.scroll = new BSscroll(this.$refs.chatListcroll,{
                        click:true
                    });
                }else{
                    this.scroll.refresh();
                }
                this.scroll.scrollTo(0,this.scroll.maxScrollY)
            })
        },
        initFastScroll(){
             this.$nextTick(()=>{
                if(!this.fastMesssageScroll){
                    this.fastMesssageScroll = new BSscroll(this.$refs.fastmessagelist,{
                        click:true,
                        bounce:false
                    });
                }else{
                    this.fastMesssageScroll.refresh();
                }
            })
        },
        setIndex(){
            let listDom = this.$refs.fastmessagelist;
            let list = listDom.children[0].children;
            let listTop = Number(getComputedStyle(listDom).marginBottom.replace('px',''));
            let topHeight = listTop;
            if(this.selectTab.distanceTop < 0){
                this.selectTab.index = ''
                return;
            }
            for(let i in list){
                let dom = list[i];
                let messageDom = getComputedStyle(dom);
                let domTopHeight = Number(messageDom.marginTop.replace('px','')) + Number(messageDom.marginBottom.replace('px',''))
                let curHeight = dom.clientHeight || 0 + domTopHeight;
                topHeight += curHeight;
                let curPoint = this.selectTab.startTop + this.selectTab.distanceTop;
                if(topHeight > curPoint - curHeight/2  && topHeight < curPoint + curHeight/2){
                    this.selectTab.index = Number(i);
                    return;
                }
            }
        },
        touchstart(e){
            // this.selectTab.startTop = Math.abs(this.fastMesssageScroll.y || 0)
            this.selectTab.startTop = 0
            let postion = this.$refs.fastmessagelist.getBoundingClientRect()
            this.selectTab.distanceTop = e.touches[0].clientY - postion.top;
            // this.setIndex();
        },
        touchmove(e){
            // this.selectTab.startTop = Math.abs(this.fastMesssageScroll.y || 0)
            this.selectTab.startTop = 0
            let postion = this.$refs.fastmessagelist.getBoundingClientRect()
            this.selectTab.distanceTop = e.touches[0].clientY - postion.top;
            // this.setIndex();
        },
        touchend(e){
            // if(this.selectTab.index === '') {
            //     this.blurInput();
            //     return;
            // }
            // this.talk(this.questionList[this.selectTab.index].question)
            // this.selectTab.index = '';
            // this.selectTab.distanceTop = 0;
        },
        
    }
}
</script>
<style >
.live-question{
    height: 100%;
    padding: 2vw 2.4vw 2vw 2.4vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width:100%;
}
.live-addquesthion-background{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background:rgba(82, 82, 82, 0.5)
    /* background: #8a8a8a; */
}
.live-addquesthion-main{
    z-index: 999;
    height: 100%;
    width: 100%;
    padding:5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.live-addquesthion-block{
    background: #f6f6f6;
    /* padding-bottom: 2vw; */
    height: 54.4vw;
    width: 90%;
    border-radius: 2vw;
}
.live-addquesthion-titel{
    width: 100%;
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.live-addquesthion-input{
    padding: 0 4vw;
    margin-bottom: 2vw;
}
.live-addquesthion-buttongroup{
    display: flex;
    justify-content: space-around;
}
.live-question .question-area{
    overflow: hidden;
    flex:1 0 0;
    margin-bottom: 2.04vw;
}
.live-question .chatListcroll_li{
    font-size: 3.2vw;
    font-weight:600;
    min-height: 3.6vw;
    line-height: 3.6vw;
    /* margin:1.2vw  0; */
    padding:1.2vw ;
    display: flex;
    /* flex-wrap:wrap; */
    /* border-radius: 1.2vw; */
}
.live-question .chatListcroll_li div:first-child{
    min-width:8vw;
}
.live-question .chatListcroll_li div:nth-child(2){
    word-break: break-all;
    flex:1;
}
.live-question .chatListcroll_li .curvoice{
    align-items: center;
}


.live-question .chatListcroll_li>div:last-child{
    display: flex;
    position: relative;
}
.voiceTitleText{
    display: flex;
    align-items: center;
}
.voiceTitleTextPoint span{
    display: inline-block;
    height:1.5vw;
    width:1.5vw;
    background:#f00;
    border-radius: 50%;
}
.voicecontent{
    min-width:36vw;
    height:6.67vw;
    /* background:#9eeb6b; */
    /* background:url(../../../assets/images/voice/voice_admin.png);
    background-size:100% 100%; */
    border-radius:.33vw;
    margin-right:1vw;
}
.voicecontentgraypng{
    min-width:36vw;
    height:6.67vw;
    /* background:#9eeb6b; */
    background-size:100% 100%;
    border-radius:.33vw;
    margin-right:1vw;
}
.voicecontentgif{
    min-width:36vw;
    height:6.67vw;
    /* background:#9eeb6b; */
    background-size:100% 100%;
    border-radius:.33vw;
    margin-right:1vw;
}
.opcityBlock{
    opacity:0.7;
}
.voiceContainer{
    max-width:36vw;
    position: relative;
}
.voiceVoiceText{
    height:100%;
    width:100%;
    padding-left:8vw;
    display: flex;
    align-items: center;
    color:#000
}
.playvoiceanim{
    height:4vw;
    width:4vw;
    /* border-radius:50%; */
}

.live .voicePlay {
    width: 4vw;
    height: 4vw;
    box-sizing: border-box;
    overflow: hidden;
    transform: rotate(140deg);    
    position: relative;
    top: 10%;
    left: 2vw;
}
.voiceContener {
    border: 2px solid #000;
    border-radius: 50%;
    position: absolute;
}

.outsidediv {
    -webkit-animation: anima1 1s linear infinite;
   animation: anima1 1s linear infinite;
}
.outsidediv{
    width: 4vw;
    height: 4vw;
    top: 9px;
    left: 9px;
    /* animation: anima1 1s linear infinite; */
}

@keyframes anima1 {
    0% {
        border-color: #ececec;
    }
    50% {
        border-color: #000;
    }
    100% {
        border-color: #000;
    }
}

.voicePlayer .voiceContener:nth-child(2) {
    -webkit-animation: anima2 1s linear infinite;
}
.voiceContener:nth-child(2) {
    width: 21px;
    height: 21px;
    top: 18px;
    left: 18px;
    /* -webkit-animation: anima2 1s linear infinite; */
}
@keyframes anima2 {
    0% {
        border-color: #ececec;
    }
    50% {
        border-color: #000;
    }
    100% {
        border-color: #000;
    }
}
.voicePlayer .voiceContener:nth-child(3) {
    -webkit-animation: anima3 1s linear infinite;
}
.voiceContener:nth-child(3) {
    width: 3px;
    height: 3px;
    background: #000;
    top: 27px;
    left: 27px;
}
@keyframes anima3 {
    0% {
        border-color: #000;
    }
    50% {
        border-color: #000;
    }
    100% {
        border-color: #ececec;
    }
}
.live-question .question-cont{
    flex:1 0 0;
    width:100%;
    position: relative;
    padding: 2vw 4vw 0;
    background:#ececec;
    border-radius:2vw;
    border:2px solid #959595;    
    display: flex;
    flex-direction: column;
}
.live-question  .question-btn{
    flex: 12vw 0 0;
    position: relative;
    display:flex
}
.live-question  .comment-icon{
    height:100%;
    position: relative;
    display:flex;
    align-items: center;
    justify-content: center;
    flex:6vw 0 0;
    font-size:6vw;

}
.live-question  .sendvalue{
    width:100%;
    flex:1 0 0;
    position: relative;
}
.fast-message-list{
    position: absolute;
    bottom:calc(100% + 2vw);
    right:0;
    max-height:500%;
    width:100%;
    background:#fff;
    border:1px solid #a60852;
    border-radius:1vw;
    padding:0 2vw;
    overflow: hidden;
}
.fast-message-main{
    padding:2vw 0vw;
}
.fast-message-content{
    padding:1vw 2vw;
    font-size:3.2vw;
    line-height: 4.2vw;
    display: flex;
    align-items: center;
}
.addquestion-text{
    /* font-size: 3vw; */
    display: flex;
    justify-content: center;
    color: #1588f5;
}
.fast-message-text{
    flex:1 0 0;
}
.fast-message-del{
    flex: 5vw 0 0;
    margin-left: 1vw;
    display: flex;
    align-items: center;
}
.fast-message-delicon{
    border:1px solid #a60852;
    height: 5vw;
    width: 5vw;
    border-radius: 2.5vw;
    color: #a60852;
    font-size: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fast-message-main .curSelectTab{
    background:#98e165;
    border-radius:.5vw;
}
.live-question  .sendvalue input{
    border:1px solid #a60852;
    /* padding-right: 18vw; */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
}
.live-question  .fastsendbtn>button{
    /* position: absolute;
    right:0;
    top:0; */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /* border-right:0 solid transparent !important; */
}
.question_btn_radius>button{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.live-question .fastsendbtn button{
    color:#a60852;
    border:1px solid #a60852;
}

.live-question .fastsendbtn button:hover{
    background:#fff;
}
.live-question .fastsendbtn button:active{
    background:#81304B;
    color:#fff;
}
.live-question .sendbtn button{
    color:#a60852;
    border:1px solid #a60852;
    padding-left:3vw;
    padding-right:3vw;
    /* border-left:0 solid transparent; */
    position: relative;
}
.live-question .sendbtn button div{
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:100%;
    border-radius:4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#fff;
}
.live-question .sendbtn i{
    /* font-size:4vw; */
    font-size:5.6vw;
}
.live-question .sendbtn{
    /* font-size:4vw; */
    font-size:5.6vw;
}

.live-question  .sendbtn>button{
    /* position: absolute;
    right:0;
    top:0; */
    border-left:0 solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.live-question .sendbtn button:hover{
    background:#fff;
}
.live-question .sendbtn button:active{
    /* background:#81304B; */
    background:#fff;
    color:#81304B;
}
.live-question .red{
    color:#ff0920;
}
.live-question .purple{
    color:#81304B;
}
.live-question .blue{
    color:#1588f5;
    /* color:#9aad2a; */
    /* color:#98e165; */
}
.live-question .gray{
    color:#777;
}
.live_question_messageBox{
  width: auto;
}
</style>